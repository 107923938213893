import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getBookingOrQuote } from '@/redux/selectors/bill/data';
import { IBookingService } from '@/services/types/booking/services';
import { EProtections } from '@/services/types/core/protections';

export const useAddedTripInsuranceService = () => {
  const bookingOrQuote = useSelector(getBookingOrQuote);

  return useMemo(
    () =>
      (bookingOrQuote?.services as Array<IBookingService>)?.find(
        service => service?.category === EProtections.TRIP_INSURANCE,
      ),
    [bookingOrQuote?.services],
  );
};
