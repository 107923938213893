import React from 'react';
import { useIntl } from 'react-intl';

import AddOn from '@/components/switchback/AddOn/AddOn';
import { CARET_LARGE } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import Link from '@/components/switchback/Link/Link';
import { IAddOn } from '@/redux/selectors/listing/addons';
import { IAddOnItem } from '@/services/types/quote/IAddons';

interface IProps {
  availableItems: IAddOn[];
  selectedItems?: IAddOnItem[];
  limit?: number;
  onAddonChange?: (addon: IAddOnItem) => void;
  onSeeMoreClick?: () => void;
}

const AddOnsList: React.FC<IProps> = ({
  availableItems,
  selectedItems = [],
  limit,
  onAddonChange,
  onSeeMoreClick,
}) => {
  const intl = useIntl();
  if (!availableItems?.length) {
    return null;
  }

  const seeMoreLink = (
    <div className="mt-3">
      <Link data-testid="show-all-add-ons-link" onClick={onSeeMoreClick} className="autoType300">
        {intl.formatMessage(
          { defaultMessage: 'Show all {count} add-ons', id: 'MVXPSw' },
          { count: availableItems.length },
        )}
        <Icon width={10} className={'transform rotate-180'} name={CARET_LARGE} />
      </Link>
    </div>
  );

  return (
    <>
      <ul className="py-4">
        {availableItems.slice(0, limit).map((item: IAddOn) => (
          <li key={item.id} className="mb-6 last:mb-0">
            <AddOn
              {...item}
              onAddonChange={onAddonChange}
              quantitySelected={
                selectedItems.find(i => (i.id ? i.id === item.id : i.itemId === item.id))?.count ||
                0
              }
            />
          </li>
        ))}
      </ul>
      {limit && availableItems.length > limit && seeMoreLink}
    </>
  );
};

export default AddOnsList;
