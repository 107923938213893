import braintree, { PayPalCheckout } from 'braintree-web';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { useCreateClientTokenMutation } from '@/graphql/types-and-hooks';

interface PayPalSDKOptions {
  currency?: string;
  intent?: 'authorize' | 'capture' | 'sale' | 'tokenize';
}

interface IBraintreeProviderProps extends PayPalSDKOptions {
  components?: 'buttons' | 'messages' | 'buttons,messages';
  buyerCountry?: string;
}

interface IBraintreeProviderContext {
  initializingPayPalCheckout: boolean;
  failedToInitializePayPalCheckout: boolean;
  payPalCheckoutInstance?: PayPalCheckout;
  payPalSDKOptions: PayPalSDKOptions;
  deviceData?: string;
}

const BraintreeProviderContext = createContext<IBraintreeProviderContext>({
  initializingPayPalCheckout: true,
  failedToInitializePayPalCheckout: false,
  payPalSDKOptions: {},
});

export const usePayPalCheckoutData = () => {
  return useContext(BraintreeProviderContext);
};

export const BraintreeProvider: React.FC<React.PropsWithChildren<IBraintreeProviderProps>> = ({
  currency = 'USD',
  intent = 'authorize',
  components = 'buttons,messages',
  buyerCountry,
  children,
}) => {
  const [initializingPayPalCheckout, setInitializingPayPalCheckout] = useState(true);
  const [failedToInitializePayPalCheckout, setFailedToInitializePayPalCheckout] = useState(false);
  const [payPalCheckoutInstance, setPayPalCheckoutInstance] = useState<PayPalCheckout>();
  const [deviceData, setDeviceData] = useState<string>();

  const [createClientTokenMutation, clientTokenResult] = useCreateClientTokenMutation();
  const authorization = clientTokenResult.data?.createClientToken?.clientToken;

  useEffect(() => {
    createClientTokenMutation().catch(() => {
      setInitializingPayPalCheckout(false);
      setFailedToInitializePayPalCheckout(true);
    });
  }, [createClientTokenMutation]);

  useEffect(() => {
    if (!authorization) return;

    braintree.client
      .create({ authorization })
      .then(async client => {
        await braintree.dataCollector.create({ client }).then(dataCollector => {
          setDeviceData(dataCollector.deviceData);
        });

        return braintree.paypalCheckout.create({ client });
      })
      .then(payPalCheckout =>
        payPalCheckout.loadPayPalSDK({
          currency,
          intent,
          vault: true,
          components,
          ...(buyerCountry ? { 'buyer-country': buyerCountry } : {}),
        }),
      )
      .then(payPalCheckout => setPayPalCheckoutInstance(payPalCheckout))
      .catch(() => setFailedToInitializePayPalCheckout(true))
      .finally(() => setInitializingPayPalCheckout(false));
  }, [authorization, currency, intent, components, buyerCountry]);

  return (
    <BraintreeProviderContext.Provider
      value={{
        initializingPayPalCheckout,
        failedToInitializePayPalCheckout,
        payPalCheckoutInstance,
        payPalSDKOptions: {
          currency,
          intent,
        },
        deviceData,
      }}>
      {children}
    </BraintreeProviderContext.Provider>
  );
};
