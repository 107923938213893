import React from 'react';

import { ALERT_OCTAGON } from '@/components/switchback/Icon/assets';
import Notice, { NoticeType } from '@/components/switchback/Notice/Notice';

import css from './BillModuleMessage.module.css';

export { NoticeType as EBillModuleMessageType } from '@/components/switchback/Notice/Notice';

interface BillModuleMessageProps {
  message: string;
  type: NoticeType;
  className?: string;
}

const BillModuleMessage: React.FC<BillModuleMessageProps> = ({ message, type, className = '' }) => {
  return (
    <div
      data-testid={`bill-message-${type}`}
      className={`text autoType300 flex py-2 px-2 lg:pl-8 ${css.messageContainer} ${className}`}
      data-type={type}>
      <Notice icon={ALERT_OCTAGON} variant={type}>
        {message}
      </Notice>
    </div>
  );
};

export default BillModuleMessage;
