import { IntlShape, useIntl } from 'react-intl';

export const useInsuranceBundleFeatures = (stationaryDelivery = false, isInNewYork = false) => {
  const intl = useIntl();

  return getInsuranceBundleFeatures(stationaryDelivery, intl, isInNewYork);
};

const getInsuranceBundleFeatures = (
  stationaryDelivery = false,
  intl: IntlShape,
  isInNewYork = false,
) => {
  const insuranceBundleFeatures = [
    {
      name: intl.formatMessage({
        defaultMessage: '24/7 Customer Support',
        id: 'mVB7XB',
      }),
    },
  ];

  if (isInNewYork) {
    insuranceBundleFeatures.push(
      {
        name: intl.formatMessage({
          defaultMessage: '$1.25M Liability Limits',
          id: '2Qetv8',
        }),
      },
      {
        name: intl.formatMessage({
          defaultMessage: '$1.25M Uninsured Motorist Limits',
          id: 'IDZl0H',
        }),
      },
    );
  } else {
    insuranceBundleFeatures.push({
      name: intl.formatMessage({
        defaultMessage: 'Minimum Liability Limits',
        id: 'VwhVAh',
      }),
    });
  }

  insuranceBundleFeatures.push(
    {
      name: intl.formatMessage({
        defaultMessage: 'Physical Damage ($300K)',
        id: 'aagsk1',
      }),
    },
    ...(!stationaryDelivery
      ? [
          {
            name: intl.formatMessage({
              defaultMessage: 'Roadside Assistance',
              id: 'Y5DEh8',
            }),
          },
          {
            name: intl.formatMessage({
              defaultMessage: 'Windshield Coverage',
              id: 'nBDxbI',
            }),
          },
        ]
      : []),
  );

  return insuranceBundleFeatures;
};
