import { useCallback, useState } from 'react';

export const useVisibilityState = (initialState = false) => {
  const [isVisible, setIsVisible] = useState(initialState);

  return {
    isVisible,
    show: useCallback(() => {
      setIsVisible(true);
    }, []),
    hide: useCallback(() => {
      setIsVisible(false);
    }, []),
    toggle: useCallback(() => {
      setIsVisible(currentIsVisible => {
        return !currentIsVisible;
      });
    }, []),
  };
};
