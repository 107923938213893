import React from 'react';

interface MessageOptions {
  layout?: string;
  color?: string;
  logoType?: string;
  amount?: string;
  className?: string;
  size?: number;
  align?: string;
  offer?: string;
}

const PayPalMessages: React.FC<MessageOptions> = ({
  layout = 'text',
  color = 'black',
  logoType = 'none',
  align = 'left',
  amount,
  className = undefined,
  size = 14,
  offer,
}) => {
  return (
    <div
      className={className}
      data-pp-message
      data-pp-layout={layout}
      data-pp-style-text-color={color}
      data-pp-style-logo-type={logoType}
      data-pp-style-text-size={size}
      data-pp-style-text-align={align}
      data-pp-amount={amount}
      data-pp-offer={offer}
    />
  );
};

export default PayPalMessages;
