import {
  Alert,
  Button,
  Divider,
  EAlertVariant,
  EButtonColorVariant,
  EHeadingStyleVariant,
  EModalFooterVariants,
  EModalHeaderTextAlignVariant,
  EModalSize,
  EModalVariants,
  ETextStyleVariant,
  Heading,
  Modal,
  Text,
} from '@outdoorsyco/bonfire';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import GuestDetail from '@/components/ui/SearchHeader/NavbarSearch/DesktopNavbarSearch/DetailsContent/GuestDetail';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { IOccupancy } from '@/services/types/core/quotes';

interface IOccupancyModal {
  isOpened: boolean;
  totalSleeps: number;
  totalPets: number;
  isSubmittingOccupancyChange: boolean;
  footerMessage: React.ReactNode;
  bookingOrQuoteOccupancy?: IOccupancy;
  bookingOrQuotePets?: number;
  onClose: () => void;
  onChangeOccupancy: (newOccupancy: IOccupancy, newPets: number) => void;
  onSubmit: (newOccupancy: IOccupancy, newPets: number) => void;
}

type TOccupancy = 'adults' | 'children' | 'infants';

export const OccupancyModal: React.FC<IOccupancyModal> = ({
  isOpened,
  totalSleeps,
  totalPets,
  isSubmittingOccupancyChange,
  footerMessage,
  bookingOrQuoteOccupancy,
  bookingOrQuotePets,
  onClose,
  onChangeOccupancy,
  onSubmit,
}) => {
  const intl = useIntl();
  const [currentGuests, setCurrentGuests] = useState<IOccupancy>({
    adults: 0,
    children: 0,
    infants: 0,
  });
  const [currentPets, setCurrentPets] = useState<number>(0);
  const { isMobile } = useBreakpoint();
  const totalCurrentGuests = currentGuests.adults + currentGuests.children + currentGuests.infants;
  const didChangeOccupancy =
    !bookingOrQuoteOccupancy ||
    currentGuests.adults !== bookingOrQuoteOccupancy.adults ||
    currentGuests.children !== bookingOrQuoteOccupancy.children ||
    currentGuests.infants !== bookingOrQuoteOccupancy.infants;

  const maxCapacityTitle = intl.formatMessage({
    defaultMessage: 'Maximum guests reached',
    id: 'PkqdJC',
  });
  const maxCapacityContent = intl.formatMessage(
    {
      defaultMessage:
        'This rental allows up to {totalSleeps, plural, one {# guest} other {# guests}}',
      id: '69yvk/',
    },
    { totalSleeps },
  );
  const maxPetsTitle = intl.formatMessage({
    defaultMessage: 'Maximum pets reached',
    id: 'zoJLyx',
  });
  const maxPetsContent = intl.formatMessage(
    {
      defaultMessage: 'This rental allows up to {totalPets, plural, one {# pet} other {# pets}}',
      id: 'NErxup',
    },
    { totalPets },
  );

  const guestOccupancyMinMaxValues = useMemo(() => {
    const maxSleepsLimitReached = totalCurrentGuests === totalSleeps;

    // note: not including min values for children/infants because 0 is the default
    return {
      adults: {
        min: 1,
        max: maxSleepsLimitReached ? currentGuests.adults : totalSleeps,
      },
      children: { max: maxSleepsLimitReached ? currentGuests.children : totalSleeps },
      infants: { max: maxSleepsLimitReached ? currentGuests.infants : totalSleeps },
    };
  }, [totalSleeps, totalCurrentGuests, currentGuests]);

  useEffect(() => {
    if (!bookingOrQuoteOccupancy) {
      return;
    }

    // default to 2 adults if the user hasn't entered guests
    // (should auto happen on quote creation)
    const occupancy = bookingOrQuoteOccupancy?.adults
      ? bookingOrQuoteOccupancy
      : { adults: 2, children: 0, infants: 0 };

    handleChangeGuestsCount(occupancy);
  }, [bookingOrQuoteOccupancy]);

  useEffect(() => {
    if (!bookingOrQuotePets) {
      return;
    }

    setCurrentPets(bookingOrQuotePets);
  }, [bookingOrQuotePets]);

  const handleChangeGuestsCount = (newCurrentGuests: IOccupancy) => {
    setCurrentGuests(newCurrentGuests);
  };

  const handleChangeGuests = (value: number, type: TOccupancy) => {
    const newCurrentGuests = { ...currentGuests, [type]: value };
    handleChangeGuestsCount(newCurrentGuests);
    onChangeOccupancy(newCurrentGuests, currentPets);
  };

  const handleChangePets = (value: number) => {
    setCurrentPets(value);
    onChangeOccupancy(currentGuests, value);
  };

  const handleCloseModal = useCallback(() => {
    if (bookingOrQuoteOccupancy) {
      setCurrentGuests(bookingOrQuoteOccupancy);
    }
    onClose?.();
  }, [bookingOrQuoteOccupancy, onClose]);

  return (
    <Modal
      size={EModalSize.Medium}
      isOpen={isOpened}
      onClose={handleCloseModal}
      variant={isMobile ? EModalVariants.Bottom : undefined}>
      <Modal.Actions />

      <Modal.Header textAlign={EModalHeaderTextAlignVariant.Left}>
        <Heading variant={EHeadingStyleVariant.H5}>
          <FormattedMessage defaultMessage="Guests" id="NIVTxd" />
        </Heading>
      </Modal.Header>

      <Modal.Content>
        <div className="pt-4">
          <GuestDetail
            adultsValue={currentGuests.adults}
            childrenValue={currentGuests.children}
            infantsValue={currentGuests.infants}
            petsValue={currentPets}
            isGuestOccupancyAvailable
            isPetOccupancyAvailable
            guestOccupancyMinMaxValues={guestOccupancyMinMaxValues}
            maxPets={totalPets}
            onChangeAdults={value => handleChangeGuests(value, 'adults')}
            onChangeChildren={value => handleChangeGuests(value, 'children')}
            onChangeInfants={value => handleChangeGuests(value, 'infants')}
            onChangePets={value => handleChangePets(value)}
          />
        </div>

        {totalCurrentGuests === totalSleeps && (
          <Alert
            className="w-full mt-4"
            title={maxCapacityTitle}
            content={maxCapacityContent}
            variant={EAlertVariant.Info}
          />
        )}

        {currentPets === totalPets && (
          <Alert
            className="w-full mt-4"
            title={maxPetsTitle}
            content={maxPetsContent}
            variant={EAlertVariant.Info}
          />
        )}
      </Modal.Content>

      <Modal.Footer variant={EModalFooterVariants.Actions}>
        <Divider className="mb-6" />
        <div className="flex flex-col items-center justify-between w-full gap-y-4 md:flex-row">
          <Text
            variant={ETextStyleVariant.MediumBold}
            className={
              !footerMessage || !didChangeOccupancy || isSubmittingOccupancyChange
                ? 'invisible whitespace-pre-line'
                : 'whitespace-pre-line'
            }>
            {footerMessage}
          </Text>
          <Button
            className="w-full md:w-auto"
            label={intl.formatMessage({ defaultMessage: 'Confirm', id: 'N2IrpM' })}
            onClick={() => onSubmit(currentGuests, currentPets)}
            variant={EButtonColorVariant.Primary}
            disabled={isSubmittingOccupancyChange}
            loading={isSubmittingOccupancyChange}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
