import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Help from '@/components/switchback/Help/Help';
import Icon from '@/components/switchback/Icon/Icon';
import { ICONS, IconSize } from '@/components/switchback/Icon/Icon.constants';
import Text from '@/components/switchback/Text/Text';
import { TCurrency } from '@/config/locales';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { getListingLocation } from '@/redux/selectors/listing/page';
import { IInsuranceBundle } from '@/services/types/booking/insuranceBundle';
import { formatCurrency } from '@/utility/currency';

import classes from './InsuranceModal.module.css';
import InsuranceRadio from './InsuranceRadio';

const tableRows = (state: string | undefined) => {
  let rows = [
    {
      id: 0,
      name: <FormattedMessage defaultMessage="Concierge Services" id="MQVYk/" />,
      helpText: (
        <FormattedMessage
          defaultMessage="Get assistance with reservations, coordinating travel arrangements, and more."
          id="IIYl+D"
        />
      ),
      values: [true, true, true],
    },
    {
      id: 1,
      name: <FormattedMessage defaultMessage="Mobile Mechanic" id="S1168C" />,
      helpText: (
        <FormattedMessage
          defaultMessage="Provides the dispatching of a mechanic to the site of your mechanically-disabled vehicle."
          id="1zxGWV"
        />
      ),
      values: [false, true, true],
    },
    {
      id: 2,
      name: <FormattedMessage defaultMessage="RV Assistance Hotline" id="XTzLDh" />,
      helpText: (
        <FormattedMessage
          defaultMessage="Access RV technicians 24/7 to guide you through common operational issues you may experience."
          id="9nMPsG"
        />
      ),
      values: [false, false, true],
    },
    {
      id: 3,
      name: <FormattedMessage defaultMessage="Liability up to $500K" id="GeJcM9" />,
      helpText: (
        <FormattedMessage
          defaultMessage="Additional protection to help cover the costs of damages or injuries if you’re found at fault in an accident."
          id="N1swpH"
        />
      ),
      values: [false, false, true],
    },
    {
      id: 4,
      name: <FormattedMessage defaultMessage="Accident Interruption" id="cakji1" />,
      helpText: (
        <FormattedMessage
          defaultMessage="Get refunded faster for unused rental expenses if an accident interrupts your trip. This helps you have the funds to book another RV on the spot."
          id="2Hyp8H"
        />
      ),
      values: [false, false, true],
    },
  ];

  if (state === 'NY') {
    rows = rows.filter(a => a.id !== 3);
  }

  return rows;
};

const DotIcon: React.FC<{ checked?: boolean }> = ({ checked = false }) => (
  <div
    className={`${
      checked ? 'bg-green-500' : 'bg-gray-300'
    } flex justify-center items-center rounded w-2 h-2 mx-auto`}
  />
);

interface IDeductibleTableProps {
  isBundleAdjustable?: boolean;
  allPlansInfo: React.ReactElement;
  insuranceBundles: IInsuranceBundle[];
  originalBundle?: IInsuranceBundle;
  selectedBundleId?: string;
  onSelectedBundleChange: (bundle: IInsuranceBundle) => void;
  currency: TCurrency;
}

const DeductibleTable: React.FC<IDeductibleTableProps> = ({
  isBundleAdjustable = true,
  allPlansInfo,
  insuranceBundles,
  originalBundle,
  selectedBundleId,
  onSelectedBundleChange,
  currency,
}) => {
  const { isMobile } = useBreakpoint();

  const listingLocation = useSelector(getListingLocation);

  const [openedId, setOpenedId] = useState<number>();
  const toggleRow = (id: number) => {
    setOpenedId(openedId === id ? undefined : id);
  };

  // Show the header using regular table head and match the columns with actual content
  // when we are not on mobile or if we have just a single bundle to display (as we have
  // enough space in this situation).
  // Otherwise, show all plans info and radio buttons on separated lines (mobile view).
  const showHeaderInsideTable = !isMobile || insuranceBundles.length === 1;

  const rows = useMemo(() => {
    return tableRows(listingLocation?.state);
  }, [listingLocation?.state]);

  return (
    <>
      {!showHeaderInsideTable && (
        <div>
          <div className="mt-6 text-sm text-gray-500 font-highlight">{allPlansInfo}</div>
          <div className="mt-6 grid grid-cols-3 gap-2">
            {insuranceBundles.map((bundle, index) => (
              <InsuranceRadio
                key={bundle.id}
                index={index}
                isCurrentPlan={originalBundle ? bundle.id === originalBundle.id : undefined}
                checked={bundle.id === selectedBundleId}
                onChange={() => onSelectedBundleChange(bundle)}
                insuranceBundle={bundle}
                currency={currency}
              />
            ))}
          </div>
        </div>
      )}
      <table
        className={classes.deductible_table}
        data-multiple-options={insuranceBundles.length > 1}>
        {showHeaderInsideTable && (
          <thead>
            <tr>
              <th className="pb-5 text-sm text-gray-500 font-highlight md:pb-4">{allPlansInfo}</th>
              {insuranceBundles.map((bundle, index) => (
                <th key={bundle.id} className="pb-5 text-left md:pb-4 md:px-1">
                  <div className={`text-center h-full ${classes.cell_wrapper}`}>
                    <InsuranceRadio
                      disabled={!isBundleAdjustable}
                      index={index}
                      isCurrentPlan={originalBundle ? bundle.id === originalBundle.id : undefined}
                      checked={bundle.id === selectedBundleId}
                      onChange={() => onSelectedBundleChange(bundle)}
                      insuranceBundle={bundle}
                      currency={currency}
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          <tr data-closed="true">
            <th>
              <FormattedMessage defaultMessage="Deductible" id="q2NyZI" />
            </th>
            {insuranceBundles.map(bundle => (
              <th
                key={bundle.name}
                className={`text-left ${bundle.id === selectedBundleId ? 'text-green-500' : ''}`}>
                <div className={`text-center ${classes.cell_wrapper}`}>
                  {!showHeaderInsideTable && (
                    <div className="flex items-center justify-center h-8 mb-2">
                      {bundle.id === selectedBundleId && (
                        <Text type="paragraph" className="text-xs font-medium uppercase">
                          {bundle.name}
                        </Text>
                      )}
                    </div>
                  )}
                  {formatCurrency({
                    priceInCents: bundle.deductible,
                    currency: currency,
                    digits: 0,
                  })}
                </div>
              </th>
            ))}
          </tr>
          {rows.map((row, index) => (
            <React.Fragment key={index}>
              <tr data-row="true" data-closed={openedId !== row.id}>
                <th className="flex">
                  <span>{row.name}</span>
                  {isMobile ? (
                    <Icon
                      onClick={() => toggleRow(row.id)}
                      className={`transform ml-1 w-3 h-3 mt-1 cursor-pointer shrink-0 ${
                        row.id === openedId ? 'rotate-90' : '-rotate-90'
                      }`}
                      name={ICONS.CARET_LARGE}
                      size={IconSize.normal}
                    />
                  ) : (
                    <Help
                      className="ml-2"
                      label={row.name as unknown as string}
                      content={row.helpText as unknown as string}
                      tooltipClassName={
                        index === rows.length - 1 ? 'mb-2 top-auto bottom-full' : ''
                      }
                    />
                  )}
                </th>
                {insuranceBundles.map((bundle, index) => (
                  <td key={bundle.name} className="text-left">
                    {row.values[index] ? (
                      <div className={`text-center ${classes.cell_wrapper}`}>
                        <DotIcon checked={bundle.id === selectedBundleId} />
                      </div>
                    ) : null}
                  </td>
                ))}
              </tr>
              {isMobile && openedId === row.id && (
                <tr data-closed="true">
                  <td className="pt-1 text-gray-500" colSpan={4}>
                    {row.helpText}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default DeductibleTable;
