import React from 'react';

import Heading from '@/components/switchback/Heading/Heading';
import { ALERT_CIRCLE } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import Text from '@/components/switchback/Text/Text';
import { IUserNoticeEvent } from '@/services/types/booking/details';

interface UserNoticeEventsProps {
  event: IUserNoticeEvent;
}

const UserNoticeEvent = ({ event }: UserNoticeEventsProps) => {
  if (!event?.guest_user_text) return null;
  return (
    <div className="flex items-start p-4 mt-4 bg-gray-100 rounded-lg gap-4" role="alert">
      <div>
        <Icon name={ALERT_CIRCLE} width={18} className="text-sand-100" />
      </div>
      <div>
        <Heading level={3} className="mb-2 highlight autoType300 text-gray-50">
          {event.name}
        </Heading>
        <Text type="paragraph" className="text autoType300 text-gray-50">
          {event.guest_user_text}
        </Text>
      </div>
    </div>
  );
};

export default UserNoticeEvent;
