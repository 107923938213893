import kebabCase from 'lodash/kebabCase';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import QuantitySelector, {
  IQuantitySelectorSize,
} from '@/components/switchback/QuantitySelector/QuantitySelector';
import Text from '@/components/switchback/Text/Text';
import { IAddOn } from '@/redux/selectors/listing/addons';
import { IAddOnItem } from '@/services/types/quote/IAddons';
import { generateSimpleId } from '@/utility/helpers';

import ClampedText from '../ClampedText/ClampedText';
import css from './AddOn.module.css';
import AddOnCheckbox from './AddOnCheckbox/AddOnCheckbox';

type TProps = Omit<IAddOn, 'currency'>;

interface IProps extends TProps {
  onAddonChange?: (addon: IAddOnItem) => void;
  quantityAvailable?: number;
}

const AddOn: React.FC<IProps> = ({
  description,
  name: title,
  price,
  priceInfo,
  priceRules,
  onAddonChange,
  quantityAvailable,
  quantitySelected,
  id,
  daily,
}) => {
  const intl = useIntl();
  const fieldId = id?.toString() ?? generateSimpleId();
  const [quantity, setQuantity] = useState(
    quantitySelected !== undefined && quantitySelected > 0 ? quantitySelected : 0,
  );
  const isChecked = useMemo(
    () => (quantitySelected !== undefined ? !!quantitySelected : !!quantity),
    [quantitySelected, quantity],
  );

  const elementsIds = useMemo(() => {
    return {
      input: `${fieldId}`,
      description: `${fieldId}-description`,
      label: `${fieldId}-label`,
    };
  }, [fieldId]);

  // screen reader messages
  const srPrice = intl.formatMessage(
    {
      description: 'Addon Price - Label for improve screen reader message',
      defaultMessage: '{price} {priceInfo}',
      id: 'fSAtJz',
    },
    {
      price,
      priceInfo,
    },
  );
  const sRMax = intl.formatMessage(
    {
      defaultMessage: 'There {quantityAvailable, plural, one {is #} other {are #}} available',
      id: 'q0befh',
    },
    { quantityAvailable },
  );
  const sRLabel = `${title}, ${srPrice}`;

  const handleChange = useCallback(
    (newQuantity: number) => {
      setQuantity(newQuantity);
      onAddonChange?.({ id, count: newQuantity, daily });
    },
    [onAddonChange, setQuantity, daily, id],
  );

  const handleCheckboxChange = useCallback(() => {
    const newQuantity =
      quantitySelected !== undefined ? (!quantitySelected ? 1 : 0) : !quantity ? 1 : 0;
    setQuantity(newQuantity);
    onAddonChange?.({ id, count: newQuantity, daily });
  }, [onAddonChange, setQuantity, quantity, daily, id, quantitySelected]);

  const Title: React.FC<React.HTMLAttributes<HTMLLabelElement>> = ({ className, ...props }) => {
    return (
      <label
        aria-label={sRLabel}
        className={`pr-4 highlight font-regular autoType600 ${className}`}
        data-testid="AddOn-title"
        {...props}>
        {title}
      </label>
    );
  };

  return (
    <div className="relative" data-testid={`add-on-${kebabCase(title)}`}>
      <div data-testid="addon-item">
        <div className="flex items-center mb-1">
          {onAddonChange && (
            <AddOnCheckbox
              aria-describedby={elementsIds.description}
              isChecked={isChecked}
              label={sRLabel}
              quantity={quantity}
              quantityAvailable={quantityAvailable}
              onChange={handleCheckboxChange}
            />
          )}
          <Title id={elementsIds.label} className="w-2/3" />
          {onAddonChange && !!quantityAvailable && quantityAvailable > 1 && (
            <QuantitySelector
              aria-describedby={elementsIds.description}
              className="mr-1 md:ml-10 lg:ml-15"
              data-testid="item-count"
              id={`${kebabCase(title)}-item-count`}
              label={`${sRLabel}. ${sRMax}.`}
              maxQuantity={quantityAvailable}
              name={kebabCase(title)}
              size={IQuantitySelectorSize.small}
              quantity={quantitySelected !== undefined ? quantitySelected : quantity}
              onChange={handleChange}
            />
          )}
          <Text
            className={`${css.price} relative ml-auto font-medium text-right whitespace-nowrap highlight autoType600`}
            type="inline">
            <span aria-hidden>
              {price}
              {priceInfo && (
                <span className="absolute right-0 block font-light text autoType200 top-full">
                  /{priceInfo}
                </span>
              )}
            </span>
          </Text>
        </div>
        <div className={onAddonChange ? 'ml-8 md:ml-9' : ''} id={elementsIds.description}>
          {priceRules && <p className="w-3/5 font-bold text-gray-900 autoType300">{priceRules}</p>}
          {description && (
            <div className="w-3/5 text-gray-900 autoType350">
              <ClampedText
                clamp={3}
                readMore
                readLess
                readMoreAriaLabel={intl.formatMessage(
                  {
                    defaultMessage: 'Details about {title}',
                    id: 'mhauOV',
                  },
                  { title },
                )}
                readLessAriaLabel={intl.formatMessage(
                  {
                    defaultMessage: 'Shrink details about {title}',
                    id: 'CPL15N',
                  },
                  { title },
                )}>
                {description}
              </ClampedText>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddOn;
