import classNames from 'classnames';
import React, { ChangeEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  PROTECT_ACTIVE,
  PROTECT_PLUS_ACTIVE,
  PROTECT_STAR_ACTIVE,
} from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import RadioBox from '@/components/switchback/RadioBox/RadioBox';
import Text from '@/components/switchback/Text/Text';
import { TCurrency } from '@/config/locales';
import { IInsuranceBundle } from '@/services/types/booking/insuranceBundle';
import { formatCurrency } from '@/utility/currency';

import classes from './InsuranceModal.module.css';

interface IInsuranceRadioProps {
  disabled?: boolean;
  checked: boolean;
  index: number;
  currency: TCurrency;
  insuranceBundle: IInsuranceBundle;
  onChange: ChangeEventHandler<HTMLInputElement>;
  isCurrentPlan?: boolean | null;
}

const InsuranceRadio: React.FC<IInsuranceRadioProps> = ({
  disabled,
  index,
  checked,
  currency,
  insuranceBundle,
  onChange,
  isCurrentPlan = null,
}) => {
  const priceLabel = formatCurrency({
    priceInCents: insuranceBundle.total_price,
    currency: currency,
    digits: 2,
  });

  let icon = null;
  switch (index) {
    case 0:
      icon = PROTECT_ACTIVE;
      break;
    case 1:
      icon = PROTECT_PLUS_ACTIVE;
      break;
    case 2:
      icon = PROTECT_STAR_ACTIVE;
      break;
    default:
      icon = PROTECT_ACTIVE;
      break;
  }
  const hasComment = isCurrentPlan === true || (isCurrentPlan === null && index === 1);

  return (
    <div className="flex flex-col items-center h-full">
      {isCurrentPlan === true && (
        <Text type="paragraph" className="mb-2 text-xs text-center text-gray-500 font-highlight">
          <FormattedMessage defaultMessage="CURRENT PLAN" id="SaYYW7" />
        </Text>
      )}
      {isCurrentPlan === null && index === 1 && (
        <Text
          type="paragraph"
          className={classNames('mb-2 text-xs text-center font-medium text-gray-500')}>
          <FormattedMessage defaultMessage="RECOMMENDED" id="/CXAa1" />
        </Text>
      )}
      <RadioBox
        disabled={disabled}
        className={classNames(classes.radio, { ['pt-6']: !hasComment })}
        checked={checked}
        labelClassName={`h-full ${classes.radio_label}`}
        label={
          <Icon
            className={`${classes.icon} ${checked && classes.isChecked}`} //{`mr-12 md:mr-16 ${checked && 'bg-red-800'}`}
            name={icon}
            height={32}
            width={32}
          />
        }
        name="insurance-bundles"
        onChange={onChange}
        data-testid={`${insuranceBundle.id}-bundle-id`}
        value={insuranceBundle.id}>
        <div
          className={classNames('text-sm highlight mt-2.5 text-gray-900', {
            ['text-gray-800']: checked,
          })}>
          <div>{insuranceBundle.name}</div>
          <div className="mt-2">{priceLabel}</div>
        </div>
      </RadioBox>
    </div>
  );
};

export default InsuranceRadio;
