import React from 'react';

import Checkbox, {
  CheckboxAlignment,
  CheckboxSizes,
} from '@/components/switchback/Checkbox/Checkbox';
import { CHECKMARK } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';

import css from './AddOnCheckbox.module.css';

interface IProps {
  isChecked: boolean;
  label: string;
  quantity: number;
  quantityAvailable?: number;
  onChange: () => void;
}

const AddOnCheckbox: React.FC<IProps> = ({
  isChecked,
  label,
  quantity,
  quantityAvailable,
  onChange,
  ...rest
}) => {
  return (
    <>
      {quantityAvailable && quantityAvailable > 1 ? (
        /**
         * Switches checkbox for a span to remove from focusable tree
         */

        <div data-testid={`checkbox-${!quantity ? 'selected' : 'not-selected'}`}>
          <span
            className={css.checkbox}
            data-checked={quantity > 0 || isChecked}
            data-selected={quantity > 0 || isChecked}
            data-testid="checkbox-input"
            onClick={onChange}>
            <Icon
              className={`opacity-0 transition-opacity duration-150 ease-in-out w-full h-full ${css.icon}`}
              name={CHECKMARK}
            />
          </span>
        </div>
      ) : (
        <Checkbox
          align={CheckboxAlignment.top}
          aria-checked={isChecked}
          aria-label={label}
          checkBoxSize={CheckboxSizes.large}
          checked={isChecked}
          data-checked={isChecked}
          testId={`checkbox-${!quantity ? 'selected' : 'not-selected'}`}
          onChange={onChange}
          className={css.checkboxComponent}
          {...rest}
        />
      )}
    </>
  );
};

export default AddOnCheckbox;
