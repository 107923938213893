import { createSelector } from 'reselect';

import { TRootState } from '../rootReducer';

export const getAvailableService = createSelector(
  [
    (state: TRootState) => state.availableServices.services,
    (_: TRootState, category: string) => category,
  ],
  (services, category) => {
    return services?.find(s => s?.category === category);
  },
);

export const getAvailableServicesLoading = createSelector<
  TRootState,
  TRootState['availableServices'],
  boolean
>(
  state => state.availableServices,
  data => data.loading,
);
