import { useSelector } from 'react-redux';

import { TRootState } from '@/redux/rootReducer';
import {
  getAvailableService,
  getAvailableServicesLoading,
} from '@/redux/selectors/availableServices';
import { getBookingOrQuoteSiblings } from '@/redux/selectors/bill/data';
import { EProtections } from '@/services/types/core/protections';

import { useAddedRoamlyWeatherService } from './useAddedRoamlyWeatherService';
import { useAddedTripInsuranceService } from './useAddedTripInsuranceService';

export const useBillModuleProtectionData = () => {
  const availableTripInsuranceService = useSelector((state: TRootState) =>
    getAvailableService(state, EProtections.TRIP_INSURANCE),
  );
  const availableRoamlyWeatherInsuranceService = useSelector((state: TRootState) =>
    getAvailableService(state, EProtections.WEATHER_PROTECTION),
  );
  const availableServicesLoading = useSelector(getAvailableServicesLoading);

  const addedTripInsuranceService = useAddedTripInsuranceService();
  const addedRoamlyWeatherInsuranceService = useAddedRoamlyWeatherService();

  const siblings = useSelector(getBookingOrQuoteSiblings);

  const availableServiceIds = [
    availableTripInsuranceService?.id,
    addedTripInsuranceService?.id,
    availableRoamlyWeatherInsuranceService?.id,
    addedRoamlyWeatherInsuranceService?.id,
  ].filter(id => id != null);

  const showProtectionStep =
    !!availableTripInsuranceService || !!availableRoamlyWeatherInsuranceService;

  const shouldSkipProtection =
    !availableTripInsuranceService &&
    !availableRoamlyWeatherInsuranceService &&
    !availableServicesLoading;

  // disabling all the trip protections for Outdoorsy stay
  if (siblings?.rental_campsite) {
    return {
      showProtectionStep: false,
      shouldSkipProtection: true,
      availableTripInsuranceService: undefined,
      addedTripInsuranceService,
      availableRoamlyWeatherInsuranceService: undefined,
      addedRoamlyWeatherInsuranceService,
      availableServiceIds: [],
    };
  }

  return {
    showProtectionStep,
    shouldSkipProtection,
    availableTripInsuranceService,
    addedTripInsuranceService,
    availableRoamlyWeatherInsuranceService,
    addedRoamlyWeatherInsuranceService,
    availableServiceIds,
  };
};
