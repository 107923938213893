import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';
import { IBooking } from '@/services/types/booking/details';
import { formatCurrency } from '@/utility/currency';

export const getBookingOrQuoteTotal = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  TRootState['quote']['data'],
  string | null
>(
  state => state.checkout.booking,
  state => state.quote.data,
  (booking, quote) => {
    if (!quote && !booking) return null;

    const currency = (booking || quote)?.presentment_currency;

    const rvSiteTotal =
      (booking?.siblings?.rental_campsite.total_after_credits ??
        booking?.siblings?.rental_campsite.total ??
        quote?.siblings?.rental_campsite.total_after_credits ??
        quote?.siblings?.rental_campsite.total) ||
      0;

    const bookingTotal = booking?.total_after_credits ?? booking?.total;
    const quoteTotal = quote?.total_after_credits ?? quote?.total;
    const baseTotal = bookingTotal ?? quoteTotal ?? 0;

    const total = baseTotal + rvSiteTotal;

    return formatCurrency({
      priceInCents: total,
      currency,
      digits: 2,
    });
  },
);

export const getFormattedTotalFromBooking = (
  booking: Partial<IBooking> | null,
  currency?: string,
) => {
  const getCurrency = () =>
    currency || booking?.presentment_currency || booking?.locale?.locale || 'USD';

  if (booking) {
    let priceInCents =
      booking.total_with_claim_adjustments || booking.total_after_credits || booking.total || 0;

    const rvSite = booking?.siblings?.rental_campsite;
    if (rvSite) {
      priceInCents +=
        rvSite.total_with_claim_adjustments || rvSite.total_after_credits || rvSite.total || 0;
    }

    return formatCurrency({
      priceInCents,
      currency: getCurrency(),
      digits: 2,
    });
  }

  return null;
};

/** get booking.total_after_credits if not null or fallback to booking.total */
export const getRawBookingTotal = (state: TRootState) => {
  return state.checkout.booking?.total_after_credits ?? state.checkout.booking?.total ?? null;
};

export const getSecurityDeposit = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  TRootState['quote']['data'],
  number | undefined
>(
  state => state.checkout.booking,
  state => state.quote.data,
  (booking, quote) => booking?.security_deposit || quote?.security_deposit,
);
