import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';

interface IDates {
  from?: string;
  to?: string;
}
const getQuoteDates = createSelector<TRootState, TRootState['quote']['data'], IDates>(
  state => state.quote.data,
  quote => {
    if (!quote) {
      return {};
    }

    return {
      from: quote.from,
      to: quote.to,
    };
  },
);

const getBookingDates = createSelector<TRootState, TRootState['checkout']['booking'], IDates>(
  state => state.checkout.booking,
  booking => {
    if (!booking) {
      return {};
    }

    return {
      from: booking.from,
      to: booking.to,
    };
  },
);

export const getBookingOrQuoteDates = createSelector<TRootState, IDates, IDates, IDates>(
  getBookingDates,
  getQuoteDates,
  (bookingDates, quoteDates) => (bookingDates.from ? bookingDates : quoteDates),
);
